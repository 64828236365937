<template>
  <div
    class="pdw-loader is-flex is-justify-content-center is-align-items-center"
  >
    <button class="button is-loading is-large is-rounded is-info is-outlined" />
  </div>
</template>

<script>
export default {
    name: "Loader"
};
</script>

<style scoped>
.pdw-loader {
  height: 60vh;
}
</style>